import { IMainMeta } from 'components/seo';
import comingSoon from './images/coming-soon.png';
import home from './images/seo-home.png';
import industries from './images/seo-industries.png';
import services from './images/seo-services.png';
import technologies from './images/seo-technologies.png';
import backbone from './images/seo-backbone.png';
import brocoders from './images/seo-brocoders.png';
import careers from './images/seo-careers.png';
import aboutUs from './images/seo-about-us.png';
import everypig from './images/seo-everypig.png';
import gokada from './images/seo-gokada.png';
import korobox from './images/seo-korobox.png';
import ladderout from './images/seo-ladderout.png';
import dokka from './images/seo-dokka.png';
import appetini from './images/seo-appetini.png';
import condogenie from './images/seo-condogenie.png';
import beyondGreen from './images/seo-beyond-green.png';
import skilent from './images/seo-skilent.png';
import mycurrency from './images/seo-mycurrency.png';
import xellardoor from './images/seo-xellardoor.png';
import anuel from './images/seo-anuel.png';
import partnership from './images/seo-partnership.png';
import generia from './images/seo-geriana.png';
import teamAugmentationImg from './images/seo-team-augmentation.png';
import fitnessAppApi from './images/seo-fitness-app-api.png';
import fitnessMobileSolutionA from './images/seo-fitness-mobile-solution.png';
import fitnessMobileSolutionB from './images/seo-fitness-mobile-solution-b.png';
import fitnessCenterApp from './images/seo-fitness-center-app.png';
import ifit from './images/seo-ifit.png';
import gymClub from './images/seo-gym-club.png';
import adact from './images/seo-adact.png';
import hypeboard from './images/seo-hypeboard.png';
import converthero from './images/seo-converthero.png';
import myRedDog from './images/seo-myRedDog.png';

const Routes = require('./components/routes').Routes;

export const meta: { [key: string]: IMainMeta } = {
  main: {
    title: 'Brocoders - On-demand Technical Team for SaaS Products and Midsize Businesses',
    description:
      'Brocoders is a software development and consulting company that creates and transforms digital projects tailored to the client’s business needs',
    image: { src: home, width: '1200', height: '630' },
    pathname: '/',
  },
  notFound: {
    title: 'Page not found',
    description:
      'Maybe you are interested in our blog or want to view our latest work. Of course you can always write us on hello@brocoders.team',
    pathname: '/404',
  },
  devOps: {
    title: 'DevOps and Cloud Services',
    description:
      'Reinforce cooperation between the development, engineering, security, and management teams to optimize your software development life cycle (SDLC) with smooth and well-structured DevOps services by Brocoders.',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_DEVOPS,
  },
  teamAugmentation: {
    title: 'Team Augmentation Services',
    description:
      'Looking for a reliable team augmentation partner? Brocoders can help you extend your team with the required specialists and grow fast.',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_TEAM_AUGMENTATION,
  },
  webAppDev: {
    title: 'Web Application Development',
    description:
      'Powered with 8+ years of experience in web design and development, the Brocoders team treats our clients as partners, not just customers: we trust in your ideas and know how to translate your vision into leading-edge web applications and personalized websites.',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_WEB_APP_DEVELOPMENT,
  },
  mobileAppDev: {
    title: 'Our Expertise in Cross-platform Mobile Development',
    description:
      'We opt for cross-platform because it’s agile, flexible, and universal. Energy management tool, snack service, or ride-sharing app — you name it, we’ve built it.',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_MOBILE_APP_DEVELOPMENT,
  },
  mvpDevelopment: {
    title: 'MVP Development for Startups and Established Businesses',
    description: 'Reduce time-to-market and acquire early adopters with Brocoders MVP development',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  productDevelopment: {
    title: 'Product Development Service',
    description:
      'Brocoders assembles a product development team to reinvent an existing architecture or deliver a multi-functioning web solution from scratch',
    keywords: ['product development', 'product development services'],
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_PRODUCT_DEVELOPMENT,
  },
  dedicatedTeam: {
    title: 'Dedicated Development Team',
    description:
      'Cut your resource expenses, enhance time-to-market and enjoy transparent reporting with the Brocoders’ dedicated team service',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_DEDICATED_TEAM,
  },
  digitalTransformation: {
    title: 'Digital Transformation Services & Consulting',
    description:
      'Leverage the full power of top-notch digital technologies to boost your efficiency and workflow with the Brocoders’ IT &amp; Digital transformation services',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
  qaServices: {
    title: 'Software Testing Services – Dedicated QA for Your Project',
    description:
      'Hire our dedicated QA team for end-to-end software testing. We seamlessly integrate into your workflow ensuring smooth collaboration and predictable results.',
    image: { src: services, width: '1200', height: '630' },
    pathname: Routes.SERVICES_QA,
  },
  blog: {
    title: 'Blog - News, Interviews, CEO insights',
    description:
      'Dive into Brocoders in-depth insights to learn more about the latest tech trends and our relevant expertise',
    pathname: Routes.BLOG,
  },
  agritech: {
    title: 'AgriTech Software Development',
    description:
      'Brocoders leverage their agritech software development expertise to revamp the agriculture sector with improved control over land, crop yields and farm management.',
    image: { src: industries, width: '1200', height: '630' },
    keywords: ['agritech software development'],
    pathname: Routes.INDUSTRIES_AGRITECH,
  },
  fintech: {
    title: 'FinTech Software Development',
    description:
      'Brocoders deep dives into the fintech software development sector to empower financial industry players and startups with highly secured and AI-fueled solutions.',
    image: { src: industries, width: '1200', height: '630' },
    keywords: ['fintech software development'],
    pathname: Routes.INDUSTRIES_FINTECH,
  },
  rideSharing: {
    title: 'Ridesharing & Carpooling App Development',
    description:
      'Brocoders embraces disruptive carpooling app development practices to deliver multi-functioning solutions to ride-sharing startups and companies',
    image: { src: industries, width: '1200', height: '630' },
    keywords: ['ridesharing app development', 'carpooling app development'],
    pathname: Routes.INDUSTRIES_RIDESHARING,
  },
  foodTech: {
    title: 'FoodTech Software Development',
    description:
      'Brocoders is a Foodtech software development company that helps delivery services and Foodtech companies maximize their business potential and reach new clients',
    image: { src: industries, width: '1200', height: '630' },
    keywords: ['foodtech software development', 'foodtech'],
    pathname: Routes.INDUSTRIES_FOODTECH,
  },

  marTech: {
    title: 'Martech Development Services',
    description:
      'Need a reliable marketing technology partner? Brocoders can help you build custom martech software from scratch or add new functionality to your existing system.',
    image: { src: industries, width: '1200', height: '630' },
    keywords: [
      'martech services company',
      'martech software',
      'martech consulting',
      'martech systems',
      'adtech & martech development services',
      'martech apps development',
    ],
    pathname: Routes.INDUSTRIES_MARTECH,
  },
  eventsManagment: {
    title: 'Events Management Software Development',
    description:
      'Brocoders builds smart events-management solutions to enable event providers seamlessly plan and manage their projects and stay on top of their schedules',
    image: { src: industries, width: '1200', height: '630' },
    keywords: ['events management software development', 'events-management solutions'],
    pathname: Routes.INDUSTRIES_EVENTS_MANAGMENT,
  },
  edtech: {
    title: 'Elearning Software Development Company',
    description:
      'Develop your e-learning software with a team that has helped numerous startups build amazing edtech solutions',
    image: { src: industries, width: '1200', height: '630' },
    keywords: ['e-learning software'],
    pathname: Routes.INDUSTRIES_EDTECH,
  },
  proptech: {
    title: 'Proptech Software Development Services | Hire an Expert Team to Build Your Solution',
    description:
      'Hire proptech software developers to audit your solution, solve existing technical problems, and move your project forward',
    image: { src: industries, width: '1200', height: '630' },
    pathname: Routes.INDUSTRIES_PROPTECH,
  },
  nodejs: {
    title: 'Node.js Development Services - Hire Node.js experts',
    description:
      'Backed by extensive Node.js development expertise, Brocoders delivers blazing-fast solutions that let businesses capture the full value of digital tech',
    image: { src: technologies, width: '1200', height: '630' },
    keywords: ['nodejs development'],
    pathname: Routes.TECHNOLOGIES_NODEJS,
  },
  reactjs: {
    title: 'React.js Development Services - Hire React.js experts',
    description:
      'Brocoders harnesses their React.js development talents to build feature-rich web and mobile solutions that let companies maximize their business value',
    image: { src: technologies, width: '1200', height: '630' },
    keywords: ['reactjs development'],
    pathname: Routes.TECHNOLOGIES_REACTJS,
  },
  reactNative: {
    title: 'React Native Development Services - Hire React Native experts',
    description:
      'Brocoders is a React Native development company that builds innovative cross-platform solutions to let businesses hit the market at a rapid pace',
    image: { src: technologies, width: '1200', height: '630' },
    keywords: ['react native development'],
    pathname: Routes.TECHNOLOGIES_REACT_NATIVE_JS,
  },
  gatsby: {
    title: 'Gatsby.js Development Services - Hire Gatsby.js experts',
    description:
      'The Brocoders team orchestrates robust Gatsby.js development practices to build lightning-fast, future-proof and SEO-friendly web solutions',
    image: { src: technologies, width: '1200', height: '630' },
    keywords: ['gatsbyjs development'],
    pathname: Routes.TECHNOLOGIES_GATSBY,
  },
  blockchain: {
    title: 'Blockchain Development Services - Hire Blockchain experts',
    description:
      'As a Blockchain development company, Brocoders delivers tamper-proof apps that enable substantial cost reductions and enhanced workflow efficiency.',
    image: { src: technologies, width: '1200', height: '630' },
    keywords: ['blockchain development'],
    pathname: Routes.TECHNOLOGIES_BLOCKCHAIN,
  },
  aiDevelopment: {
    title: 'AI Development Services: Hire Open AI Experts & AI Engineers',
    description:
      "Integrate AI into your product or discover how you can harness the power of AI for your business. We're a team of AI developers to help you implement AI in a shorter time with lower risk.",
    image: { src: technologies, width: '1200', height: '630' },
    pathname: Routes.SERVICES_AI,
  },
  career: {
    title: 'Careers',
    description:
      'Develop your career along with Brocoders. Explore our latest open positions and join our team to work on the next big project.',
    image: { src: careers, width: '1200', height: '630' },
    pathname: Routes.CAREER,
    keywords: ['career'],
  },
  cases: {
    title: 'Case Studies',
    description:
      'Solutions successfully built by the Brocoders team. Learn more about our expertise',
    pathname: '/case-studies',
  },
  aboutUs: {
    title: 'About us',
    description: 'Learn more about the Brocoders team that a lot of companies enjoy working with',
    image: { src: aboutUs, width: '1200', height: '630' },
    pathname: '/about-us',
  },
  getInTouch: {
    title: 'Get in touch',
    description:
      'Do you have a software development project to implement? Tell us about your challenge to estimate your idea and move on to building fruitful collaboration',
    pathname: '/get-in-touch',
  },
  dokka: {
    title: 'Fintech software for accounting and bookkeeping',
    description:
      'Brocoders used custom development know-hows to create software for accounting that enhances the speed and quality of financial document management',
    keywords: ['software for accounting', 'software for bookkeeping'],
    image: { src: dokka, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_DOKKA,
  },
  everyPigLogistic: {
    title: 'Logistics platform for swine production industry | EveryPig Case Study',
    description:
      'Brocoders applied to the best software development practices to build a logistics platform for managing the entire pork life-cycle from production to sales',
    keywords: ['logistics platform'],
    image: { src: everypig, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_EVERYPIG_LOGISTIC,
  },
  everyPig: {
    title: 'AI-based Swine Management Software | EveryPig case study',
    description:
      'A case study on developing a groundbreaking management software for pig owners, producers and veterinarians',
    keywords: [
      'pork producing software',
      'swine management software',
      'pig farm management software',
    ],
    image: { src: everypig, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_EVERYPIG,
  },
  gokada: {
    title: 'Ridesharing app development | Gokada Case Study',
    description:
      'Brocoders made the most of their ridesharing app development expertise to create a carpooling app designed for a motorbike taxi service in Lagos',
    keywords: ['ridesharing app development'],
    image: { src: gokada, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_GOKADA,
  },
  korobox: {
    title: 'Snacks delivery app | Korobox Case Study',
    description:
      'Backed by extensive expertise in cross-platform app development, Brocoders built a snacks delivery app to digitalize and simplify an office snack box service',
    keywords: ['snacks delivery app'],
    image: { src: korobox, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_KOROBOX,
  },
  ladderout: {
    title: 'Mental health coaching software | Ladder Out Case Study',
    description:
      'The Brocoders team managed to develop mental health coaching software to support people who require additional help in breaking free from adult content use',
    keywords: ['mental-health-software', 'mental health coaching software'],
    image: { src: ladderout, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_LADDEROUT,
  },
  backbone: {
    title:
      "Backbone International — revolutionizing events' management sector | Backbone Case Study",
    description:
      'Brocoders emerged as the optimal choice to develop feature-rich events management software that streamlines organization and execution of events of any type',
    keywords: ['events management software'],
    image: { src: backbone, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_BACKBONE,
  },
  brocoders: {
    title: 'Corporate website development | Brocoders Case Study',
    description:
      'Backed by multi-year corporate website development expertise and almost 100 projects under our belt, we reinvented our old-hat site to keep pace with the times',
    keywords: ['corporate website development'],
    image: { src: brocoders, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_BROCODERS,
  },
  comingSoon: {
    title: 'Coming soon',
    description: 'We are about to approve a case study for this project',
    image: { src: comingSoon, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_DOKKA,
  },
  cookies: {
    title: 'Brocoders Web Privacy Policy',
    description:
      'A cookie is a computer file that is downloaded to your computer when you access certain websites. Among other things, cookies allow a website to store and retrieve information on a user’s browsing habits or on their equipment and, depending on the information they contain and the way you use your equipment, they can be used to recognise a user.',
    pathname: Routes.COOKIES,
  },
  adact: {
    title: 'Marketing gamification platform | Adact case study',
    description:
      'Delivering a next-gen marketing gamification platform to build marketing campaigns without a single line of code',
    keywords: ['marketing gamification platform', 'martech platform', 'gamification in marketing'],
    pathname: Routes.CASE_STUDIES_ADACT,
    image: { src: adact, width: '1200', height: '630' },
  },
  anuel: {
    title: 'Ecommerce web and mobile platform | Fine Platform Case Study',
    description:
      'How Brocoders delivered web and mobile applications for renewable energy startup.',
    keywords: ['ecommerce platform', 'ecommerce application', 'renewable energy startup'],
    pathname: Routes.CASE_STUDIES_ANUEL,
    image: { src: anuel, width: '1200', height: '630' },
  },
  partnership: {
    title: 'Partner Network',
    description:
      'The Brocoders Partnership Program is designed to help startups and fast-growing businesses use most of our multi-year expertise in various domains.',
    pathname: Routes.PARTNERSHIP,
    image: { src: partnership, width: '1200', height: '630' },
  },
  appetini: {
    title: 'Lunch Delivery Solution | Appetini Case Study',
    keywords: ['lunch delivery solution'],
    description:
      'Take a sneak peek at how we developed a food delivery service platform to cover our needs when ordering lunches for offices in our city',
    image: { src: appetini, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_APPETINI,
  },
  condogenie: {
    title: 'Condominium Management Platform | CondoGenie Case Study',
    keywords: ['condominium management platform'],
    description:
      'A sneak peek at how Brocoders developed a feature-rich product to help condo residents, condo board directors and property managers effectively cope with numerous condominium-related issues.',
    image: { src: condogenie, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_CONDOGENIE,
  },
  converthero: {
    title: 'Exit-intent popup software | Converthero case study',
    description:
      'A case study on developing exit-intent popup software with an advanced popup builder to increase user engagement and conversion rate',
    keywords: ['exit-intent popup software', 'exit-intent popup tool'],
    pathname: Routes.CASE_STUDIES_CONVERTHERO,
    image: { src: converthero, width: '1200', height: '630' },
  },
  beyondGreen: {
    title: 'Meal Order Management Service | Beyond Green Case Study',
    keywords: ['meal order management service '],
    description:
      'Brocoders made the most of their foodservice app development expertise to create a meal order management service for a school lunch provider.',
    image: { src: beyondGreen, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_BEYOND_GREEN,
  },
  skilent: {
    title: 'Recruiting Software | Skilent Case Study',
    keywords: [
      'recruiting software',
      'staffing software',
      'talent recruiting software',
      'enterprise recruiting software',
    ],
    description:
      'How Brocoders developed a fully-featured recruiting software to help hiring managers and recruiters optimize their workflow',
    image: { src: skilent, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_SKILENT,
  },
  mycurrency: {
    title: 'Loyalty Points Exchange Platform | MyCurrency Case Study',
    keywords: [
      'loyalty points app',
      'loyalty program',
      'loyalty app',
      'loyalty points exhange app',
    ],
    description:
      'How Brocoders helped to build a comprehensive ecosystem of goods and services exchange based on the micro-currency concept',
    image: { src: mycurrency, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_MYCURRENCY,
  },
  xellardoor: {
    title: 'Fashion marketplace development | Xellardoor case study',
    keywords: ['online', 'fashion', 'b2c', 'marketplace'],
    description:
      'Take a sneak peak how Brocoders developed feature-rich online fashion marketplace Xellardoor with outstanding features for fashion enthusiasts.',
    image: { src: xellardoor, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_XELLARDOOR,
  },
  fitnessAppApiIntegrations: {
    title: 'Set Up API Integrations To Enhance Your Fitness App | Brocoders',
    description:
      'Top fitness services can help your app build trust, expand reach, get new data, and increase engagement. Connect with a leading fitness via API – Brocoders will handle the integration! ',
    pathname: Routes.FITNESS_APP_API_INTEGRATIONS,
    image: { src: fitnessAppApi, width: '1200', height: '630' },
  },
  fitnessCenterApp: {
    title: 'Gym software development company',
    description:
      'We create digital solutions that help gym owners and trainers expand their business and attract new customers.',
    pathname: Routes.FITNESS_CENTER_APP,
    keywords: ['fitness club mobile applications', 'wellness studios mobile applications'],
    image: { src: fitnessCenterApp, width: '1200', height: '630' },
  },
  fitnessMobileSolutionsA: {
    title: 'Web and mobile solutions for fitness industry | Brocoders',
    description:
      'Brocoders will envision a perfect fitness and wellness app and make it a reality! Enrich the experience of your clients and employees with a digital solution developed just for you.',
    pathname: Routes.FITNESS_MOBILE_SOLUTIONS_A,
    image: { src: fitnessMobileSolutionA, width: '1200', height: '630' },
  },
  fitnessMobileSolutionsB: {
    title: 'Fitness and Wellness App Development Company',
    keywords: [
      'fitness app development',
      'fitness solutions development',
      'fitness app development company',
    ],
    description:
      'Explore mobile solutions for the fitness industry by Brocoders company: nutrition apps, activity tracking apps, applications for fitness clubs etc.',
    pathname: Routes.FITNESS_MOBILE_SOLUTIONS_B,
    image: { src: fitnessMobileSolutionB, width: '1200', height: '630' },
  },
  swgOffer: {
    title: 'Tech team for the Family | Startup Wise Guys-backed startups',
    keywords: ['team augmentation', 'startup', 'technical support'],
    description: 'Team augmentation services and technical support for fast-growing startups.',
    pathname: Routes.SWG_OFFER,
  },
  chatGPT: {
    title: '5 Ideas for Business Owners: How Open AI and ChatGPT Can Revolutionize Your Operations',
    keywords: ['chatgpt,', 'openai', 'gpt3'],
    description:
      'Discover 5 game-changing ideas for business owners looking to revolutionize their operations with OpenAI (GPT3 technology). Automate routine tasks, improve customer experiences, and boost productivity with cutting-edge AI solutions. Unlock the potential of OpenAI and take your business to the next.',
    pathname: Routes.CHAT_GPT,
  },
  saasDevs: {
    title: 'SaaS developers',
    description: 'Hire Top SaaS developers | On-demand tech team for building SaaS products',
    pathname: Routes.DEVELOPERS_CV,
  },
  geriana: {
    title: 'Peer to Peer Elderly Caregiving platform | Geriana case study',
    description:
      "Geriana is a marketplace called to be an all-European network for providing social services. Learn more about Brocoders' commitment to the development of this project.",
    keywords: ['caregiving platform', 'caregiver support', 'assistance home care'],
    pathname: Routes.CASE_STUDIES_GERIANA,
    image: { src: generia, width: '1200', height: '630' },
  },
  hypeboard: {
    title: 'Instagram analytics tool | Hypeboard case study',
    description:
      'Developing a cross-platform mobile application that shows real-time rankings of Instagram profiles',
    keywords: ['instagram analytics tool', 'analytics dashboard'],
    image: { src: hypeboard, width: '1200', height: '630' },
    pathname: Routes.CASE_STUDIES_HYPEBOARD,
  },
  iFit: {
    title: 'New Functionality - Interactive Workout Application | Case Study',
    description:
      'How Brocoders helped create a fitness solution to help people reach their goals and live healthier lives.',
    keywords: ['interactive', 'fitness', 'software'],
    pathname: Routes.CASE_STUDIES_I_FIT,
    image: { src: ifit, width: '1200', height: '630' },
  },
  gymClub: {
    title: 'Gym Management Software Case Study',
    description:
      'Delivering a next-gen gym experience with a custom-built hardware & software package',
    keywords: [
      'gym club software',
      'smart exercise equipment',
      'gym club digitalization',
      'gym management system',
      'gym management software',
    ],
    pathname: Routes.CASE_STUDIES_GYM_CLUB,
    image: { src: gymClub, width: '1200', height: '630' },
  },
  fitnessAppCalculator: {
    title: 'Fitness App Development Cost Calculator',
    description:
      'How much does it cost to build a fitness app? Fill in your fitness app requirements and get approximate price for the app development.',
    keywords: ['app development cost', 'fitness app cost'],
    pathname: Routes.FITNESS_APP_CALCULATOR,
  },
  thanks: {
    title: 'Brocoders - On-demand Technical Team for SaaS Products and Midsize Businesses',
    description:
      'Brocoders is a software development and consulting company that creates and transforms digital projects tailored to the client’s business needs',
    image: { src: home, width: '1200', height: '630' },
    pathname: Routes.THANKS,
  },
  heypractice: {
    title: 'AI-based Interactive Learning Platform for Sales and Marketing',
    description:
      'How Brocoders helped to build an interactive digital learning platform and integrate AI-based units to create an exclusive training tool for one of the fast-growing EdTechs in Europe',
    keywords: ['app development cost', 'fitness app cost'],
    pathname: Routes.CASE_STUDIES_HEYPRACTICE,
  },
  tradersAlloy: {
    title: 'CI/CD pipeline for a Fintech startup | Traders Alloy',
    description:
      'Switching from manual to automated deployment gave Traders Alloy a smoother path to production',
    keywords: ['fintech', 'devops', 'ci/cd'],
    pathname: Routes.CASE_STUDIES_TRADERS_ALLOY,
  },
  wagepoint: {
    title:
      'Accelerating Payroll Software Renovation With a Qualified Remote Team: Wagepoint Case Study',
    description:
      'Learn how we helped a payroll software company release a high-quality product on time by adding 5 qualified engineers to the project, and further scaling the team to 13 experts',
    keywords: ['fintech', 'team augmentation'],
    pathname: Routes.CASE_STUDIES_WAGEPOINT,
  },
  areabutler: {
    title:
      'Empowering Real Estate Software Solution With a Skilled Remote Team: AreaButler Case Study',
    description:
      'How Brocoders helped to augment a team for a German PropTech startup and supported the development of the SaaS solution for real estate sales and marketing with AI-based units.',
    keywords: ['proptech', 'AI integration', 'Maps'],
    pathname: Routes.CASE_STUDIES_AREABUTLER,
  },
  saasDevelopmentServices: {
    title: 'SaaS Development Services',
    description:
      'Build a committed team or fill the required positions with the right talent — Brocoders has professional developers to start your SaaS project without delays.',
    keywords: ['saas', 'development'],
    pathname: Routes.SERVICES_SAAS_DEVELOPMENT,
  },
  hoaManagement: {
    title: 'New Application for HOA-management companies',
    description:
      'Learn how we helped a leading property management company build an app that allows homeowners to interact with their property owner’s association while reducing the workload on the company’s staff.',
    keywords: ['hoa management application'],
    pathname: Routes.CASE_STUDIES_HOA_MANAGMENT,
    image: { src: myRedDog, width: '1200', height: '630' },
  },
  revenueBoosters: {
    title:
      'Developing an MVP of a Route Management SaaS App in 3,5 Months: Revenue Boosters Case Study',
    description:
      'Learn how we helped Revenue Boosters create a scalable SaaS solution tailored to the specific needs of amusement operators.',
    pathname: Routes.CASE_STUDIES_REVENUE_BOOSTERS,
  },
};
